<template>
  <v-container
    fluid
    class="d-flex flex-column justify-space-between px-2"
    style="height: 100%;"
  >
    <v-row
      no-gutters
      class="list-top"
    >
      <h3
        class="ml-1 mr-2"
        v-text="title"
      />
    </v-row>
    <v-row
      no-gutters
      class="list-top"
    >
      <span
        class="mx-1 my-4 caption"
        v-text="description"
      />
    </v-row>
    <v-row
      no-gutters
      class="d-flex flex-column justify-start overflow-y-auto py-2"
    >
      <v-list
        v-for="(item, index) in localItems"
        :key="index + localItems.length"
        dense
        :style="{
          ...styleSettings.list,
          ...(isCategory && indexItemToSelect === index && styleSettings.selected),
          ...(duplicateElementIndex === index && styleSettings.warning)
        }"
      >
        <category-list-item
          :key="index + localItems.length + indexItemToSelect"
          :text-field="textField"
          :item="item"
          :index="textField + index"
          :duplicate="duplicateElementIndex === index"
          @onUpdate="updateItem"
          @onDelete="deleteItem"
          @onSelect="selectItem"
        />
      </v-list>
    </v-row>
    <v-row
      no-gutters
      style="flex: initial !important;"
      class="align-center"
    >
      <v-btn
        icon
        small
        color="primary"
        :disabled="disabled"
        @click="addElement"
      >
        <v-icon
          dark
        >
          add_box
        </v-icon>
      </v-btn>
      <span
        class="ml-2 primary--text"
        :class="disabled && 'inactive--text text--darken-4'"
        v-text="addButtonText"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryList',
  components: {
    CategoryListItem: () => import('@/modules/academy/components/AcademyCategoriesListItem'),
  },
  props: {
    textField: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    addButtonText: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: null,
    },
    ids: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localItems: [],
      indexItemToSelect: undefined,
    }
  },
  computed: {
    ...mapGetters({ isPublished: 'project/isPublished' }),
    isCategory({ textField }) {
      return textField === 'name'
    },
    styleSettings() {
      return {
        checkbox: {
          fontWeight: this.activeList ? '900' : '300',
          marginLeft: '2px',
          marginRight: '8px',
        },
        list: {
          background: 'white',
          border: '2px solid #dadada',
          borderRadius: '12px',
          boxShadow: `2px 2px 2px 1px ${this.$vuetify.theme.themes.light.inactive}`,
          maxWidth: '100%',
          height: '48px',
          margin: '2px',
          padding: '0px',
        },
        selected: {
          border: this.isCategory && '2px solid #2acccc',
        },
        warning: {
          border: this.textField === 'suffix' && '2px solid #e02020',
        },
      }
    },
    duplicateElementIndex({ localItems }) {
      let index
      localItems.forEach((item, i) => localItems.filter(element => element[this.textField] === item[this.textField]).length > 1 ? index = i : null)
      return index
    },
  },
  watch: {
    items(value) {
      this.localItems = [...value]
    },
  },
  async created() {
    if (this.items) this.localItems = [...this.items]
    this.focus()
  },
  methods: {
    addElement() {
      const item = this.ids ? this.ids : {}
      this.localItems.push(item)
      this.focus()
    },
    updateItem(itemToUpdate) {
      this.localItems = this.localItems.map(child => child.id === itemToUpdate.id ? { ...itemToUpdate } : child)
      this.$emit('onChanged', this.localItems)
      this.focus()
    },
    deleteItem(itemToDelete) {
      this.localItems = this.localItems.filter(child => child.id !== itemToDelete.id)
      this.$emit('onChanged', this.localItems)
      this.focus()
    },
    selectItem(itemToSelect) {
      this.indexItemToSelect = this.localItems.findIndex(item => item.id === itemToSelect.id)
      this.$emit('onSelect', itemToSelect?.id)
    },
    focus() {
      this.indexItemToSelect = this.localItems?.length - 1
      this.selectItem(this.localItems[this.indexItemToSelect])
    },
  },
}
</script>

<style lang="scss" scoped>
.list-top {
  position: relative;
  flex: none !important;
}
</style>
